import React from "react"
import {SectionTitleStyle } from "./SectionTitleStyle"

const SectionTitle = ({title}) => {
    return (
        <SectionTitleStyle>
            <div className="title-box"><div className="title">{title}</div></div>   
        </SectionTitleStyle>
    )   
  }

  export default SectionTitle