import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import FeatureStory from "../LionsStories/FeatureStory"
import { StoryStyles } from "../LionsStories/StoryStyles"
import 'bootstrap/dist/css/bootstrap.min.css'
import Button from "../Button/Button"
import { Link } from "gatsby"


const getFeaturedStories = graphql`
  query {
    featuredStories: allContentfulLionsStory(
      filter: { featured: { eq: true } }
    ) {
      edges {
        node {
          name
          excerpt
          contentful_id
          slug
          images {            
            gatsbyImageData(width: 600, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`


const FeaturedLionsStories = () => {
  const response = useStaticQuery(getFeaturedStories);
  const featuredStories = response.featuredStories.edges;

  return (
      <StoryStyles>
      <div className="title-box"><div className="title">Featured Stories</div></div>   
      <div className="features__container">
        <div className="features__container--scroll">
          {featuredStories.map(({ node }) => {
            return <FeatureStory key={node.contentful_id} feature={node} />
          })}
        </div>
      </div>
      <Button text="Read more Lions Stories" as={Link} to="/stories" />  
    </StoryStyles>
  )
}

export default FeaturedLionsStories
