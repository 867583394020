import styled from "styled-components"

export const BannerModuleStyles = styled.section`
  height: 100vh;
  position: relative;
  


  .container {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .banner__image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
  
  .banner__content {
    position: relative;
    z-index: 2;
    min-height: 33vh;    
    width: 100%;
    

    @media (min-width: 768px) {
      width: 66vw;
    }

    
    /* color added in for demo - normally inherits*/
    h1 {
      margin-top: 0;
      margin-bottom: 10px;
      font-weight: 700;
      color: #fff; 
      font-size: var(--bannerTitle);
      letter-spacing: -1px;
      margin-bottom: 30px; 
      text-shadow: -1px -1px 0 #545454, 1px -1px 0 #545454, -1px 1px 0 #545454, 1px 1px 0 #545454

    }
    .lion-svg{
      display: inline;
      width: 70px;
      height: 70px;
    }
    h2 {
      margin-top: 0;
      margin-bottom: 30px;
      font-weight: 500;
      font-size: var(--bannerSubTitle);
      letter-spacing: -0.5px;
      color: #fff; 
      text-shadow: -1px -1px 0 #545454, 1px -1px 0 #545454, -1px 1px 0 #545454, 1px 1px 0 #545454
    }

    button {
      width: 50px;
      height: 50px;
      
      background-color: rgba(255,255,255, 0.3);
      border: none;
      color: #1f2545;
      
      font-size: 50px;
      display: flex;
      margin-top: 30px;

    }

  }
`
