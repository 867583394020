import React from "react"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import FeaturedLionsStories from "../components/FeaturedLionsStories/FeaturedLionsStories"
import LionsPillars from "../components/LionsPillarsModule/LionsPillars"
import { useStaticQuery, graphql } from "gatsby"

const getLandingPage = graphql`
  query {
    config: allContentfulSiteConfiguration {
      edges {
        node {
          bannerByline
          bannerImages {
            contentful_id
            title
            description
            id
            gatsbyImageData
          }
          bannerTitle
          landingPageYouTubeByline
          landingPageYouTubeTitle
          landingPageYouTubeUrl
        }
      }
    }
  }
`

const Index = () => {
  const landingPage = useStaticQuery(getLandingPage);
  const config = landingPage.config.edges[0].node;
  return (
    <>
      <Seo pageTitle="Home" />
      <BannerModule
        config={config}
      />
      <BasicTextModule config={config}/>      
      <FeaturedLionsStories/>
      <LionsPillars/>
      
    </>
  )
}

export default Index
