import styled from "styled-components"

export const SectionTitleStyle = styled.section`

.title-box {
    margin-left: var(--borderSpacing);
    margin-right: var(--borderSpacing);
  }
.title {
    
   margin: auto;
  width: 100%;
  border-bottom: 3px solid var(--primary);
  padding: 10px;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  }
`