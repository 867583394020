import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {Carousel} from 'react-responsive-carousel'
import BackgroundImage from 'gatsby-background-image'
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"



const getConfigurableProps = () => ({
  showArrows: false,
  showStatus: false,
  showIndicators: false,
  infiniteLoop: true,
  showThumbs: false,
  useKeyboardArrows: true,
  autoPlay: true,
  stopOnHover: false,
  swipeable: false,
  dynamicHeight: true,
  emulateTouch: true,
  autoFocus: false,
  thumbWidth: 100,
  selectedItem: 0,
  interval: 7000,
  transitionTime: 5000,
  swipeScrollTolerance: 5,
  ariaLabel: undefined,
  animationHandler: "fade"  
});

const createCarouselItemImage = (bannerImage, options = {}) => {
  const image = getImage(bannerImage)
  const bgImage = convertToBgImage(image)
  return (
  <div key={bannerImage.id}>
      <BackgroundImage  
       Tag="section"
       {...bgImage}
       preserveStackingContext>
        <div style={{minHeight: "100vh", minWidth: "100vw"}}/>
       </BackgroundImage>
      
  </div>
)};





const BackgroundSlider = ({ config }) => {
  const baseChildren = <div>{config.bannerImages.map(createCarouselItemImage)}</div>;
  return (
    <>      
      <Carousel {...getConfigurableProps()} > 
        {baseChildren.props.children}
      </Carousel>      
    </>
    ) 
  } 


export default BackgroundSlider