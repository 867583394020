import React from "react"
import { LionsPillarStyles } from "./LionsPillarModuleStyles"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const LionsPillar = ({ pillar }) => {
  const {name, pillarPhoto, pillarId, pillarDescription } = pillar
  const altText = pillarPhoto.description
  
  const description = pillarDescription.pillarDescription
  const image = getImage(pillarPhoto)
  return (
    <LionsPillarStyles>
      {name && <h2 id={pillarId}>{name}<div><Link to={`${pillarId}`}>
      <GatsbyImage          
          image={image}
          alt={altText}
        />
        </Link></div></h2>}
      {description && <p>{description}</p>}      
    </LionsPillarStyles>
  )
}

export default LionsPillar
