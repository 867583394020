import React from "react"

import { LionsPillarModuleStyles } from "./LionsPillarModuleStyles"
import SectionTitle from "../SectionTitle/SectionTitle"
import LionsPillar from "../LionsPillarsModule/LionsPillar"
import { useStaticQuery, graphql } from "gatsby"

const getLionsPillars = graphql`
  query {
    lionsPillars: allContentfulLionsPillar(sort: {order: ASC, fields: displayOrder}) {
        edges {
          node {
            id
            contentful_id
            name
            pillarId
            pillarPhoto {
              description
              contentful_id
              gatsbyImageData(width: 600, formats: [AUTO, WEBP])
            }
            pillarDescription {
              pillarDescription
            }
          }
        }
      }
  }
`


const LionsPillars = () => {
  const response = useStaticQuery(getLionsPillars);
  const lionsPillars = response.lionsPillars.edges;
  return (
    
      
          <LionsPillarModuleStyles>   
          <SectionTitle title="Focus Areas"/>
   
      <div className="focus__image--overlay"></div>
        <div className="container">   
          {lionsPillars.map(({ node }) => {
            return <LionsPillar key={node.id} pillar={node} />
          })}          
        </div>
        
     
    </LionsPillarModuleStyles>
    
  
  )
}

export default LionsPillars
