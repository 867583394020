import React from "react"
import {navigate } from "gatsby"
import { BannerModuleStyles } from "./BannerModuleStyles"
import { CgArrowDownR as Arrow} from "@react-icons/all-files/cg/CgArrowDownR";
import BackgroundSlider from "./BackgroundSlider";
import LionsLogo from "../../images/logos/lionlogo.svg"



const BannerModule = ({ config }) => {
  function scrollToArea() {
    navigate("#topContent")
  }

  return (
    <>
      <BannerModuleStyles>
      <div className="banner__image">
        <BackgroundSlider config={config}/>
        </div>
        <div className="container">
          <div className="banner__content">            
            {config.bannerTitle && (
              <>
                   <h1><LionsLogo className="lion-svg"/>
                {config.bannerTitle}
                <span style={{ color: "var(--primary)" }}>.</span>
              </h1>
              </>            
            )}
            {config.bannerByline && <h2>{config.bannerByline}</h2>}           
            <button onClick={scrollToArea}>
              <Arrow />
            </button>
          </div>
        </div>
      </BannerModuleStyles>
      <span id="topContent"></span>
    </>
  )
}

export default BannerModule
