import styled from "styled-components"

export const BasicTextModuleStyles = styled.section`
  padding: 70px var(--borderSpacing);
  color: #fff;
  background-color: #1f2545;

  @media (min-width: 375px) {
    padding-top: 40px;
    padding-bottom: 30px;
  }

  @media (min-width: 768px) {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  @media (min-width: 1024px) {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .videoWrapper{
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
  }

  .videoWrapper iframe{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }

  h2 {
    font-size: 20px;
    font-weight: 700px;
    letter-spacing: -0.5px;
    margin-top: 0;
    margin-bottom: 25px;

    @media (min-width: 375px) {
      font-size: 22px;
    }

    @media (min-width: 414px) {
      font-size: 27px;
    }

    @media (min-width: 768px) {
      font-size: 30px;
    }

    @media (min-width: 1024px) {
      font-size: 36px;
    }

    @media (min-width: 1152px) {
      font-size: 28px;
    }

    @media (min-width: 1440px) {
      font-size: 30px;
    }
  }
`
