import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import { BasicTextModuleStyles } from "./BasicTextModuleStyles"




const BasicTextModule = ({config}) => {
  const youtubeExt = "?rel=0";
  const videoSrc = config.landingPageYouTubeUrl +  youtubeExt;
  return (
    
      
    <BasicTextModuleStyles>
      <div className="container">
        <h2>
          {config.landingPageYouTubeTitle}
        </h2>       
        <div className="videoWrapper">
        <iframe             
            className="embed-responsive-item"            
            src={videoSrc}
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope" 
            allowFullScreen                    
            >
        </iframe>
        </div>        
        <p style={{ marginTop: "20px" }}>
          {config.landingPageYouTubeByline}          
        </p>        
    </div>    
    </BasicTextModuleStyles>
 
 
  )
}

export default BasicTextModule
