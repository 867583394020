import styled from "styled-components"

export const LionsPillarModuleStyles = styled.section`
  padding: var(--sectionPadding) var(--borderSpacing);
  color: #fff;
  
  position: relative;
  background-image: url(/lionsbackground.svg);
  background-size: 100vw;
  background-position: top-center;
  background-repeat: repeat-y;
  background-color: #10173a;
  

  .title-box {
    margin-left: var(--borderSpacing);
    margin-right: var(--borderSpacing);    
    padding-bottom: 3vh;
  }

.title {
    
   margin: auto;
  width: 100%;
  border-bottom: 3px solid var(--primary);
  padding: 10px;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  }

  .focus__image--bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    @media (max-width: 768px) {
      display: none;     
    }
  }

  .focus__image--overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  > .container {
    position: relative;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
    margin-left: 0;
    overflow: hidden;
    
  }
`

export const LionsPillarStyles = styled.aside`
  .gatsby-image-wrapper {
    width: 100%;
    
    
    justify-content: flex-start;
    align-items: center;    
    transition: transform 0.3s ease;        
    

    img {
      width: 100%;
      height: 100%;
      object-fit: contain !important;
      object-position: left center;  
      
    }
  
    &:hover,
    &:focus {
      cursor: pointer;
      transform: scale(1.1);    
      transition: transform 0.3s ease;
    }
  }
  div {
    overflow: hidden;
  }
  h2 {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
    
    

    @media (min-width: 375px) {
      font-size: 22px;
    }

    @media (min-width: 414px) {
      font-size: 27px;
    }

    @media (min-width: 768px) {
      width: 260px;
      font-size: 22px;
    }

    @media (min-width: 1024px) {
      width: 300px;
      font-size: 26px;
    }

    @media (min-width: 1154px) {
      width: 350px;
      font-size: 26px;
    }
    
  }
  
  h2#youth{
    background-color: #6cb8bf;    
  }
  h2#health{
    background-color: #24bde6;
  }
  h2#humanitarian{
    background-color: #396cb4;
  }
  h2#emergency{
    background-color: #f15c3e;
  }
  h2#environment{
    background-color: #208f8d;
  }
  h2#research{
    background-color: #f4bc4b;
  }

  p {
    font-size: 15px;
    text-align: left;

    @media (min-width: 375px) {
      font-size: 16px;
    }

    @media (min-width: 414px) {
      font-size: 18px;
    }

    @media (min-width: 768px) {
      font-size: 19px;
    }

    @media (min-width: 1024px) {
      font-size: 20px;
    }

    @media (min-width: 1154px) {
      font-size: 18px;
    }
  }

  margin-bottom: 60px;

  @media (min-width: 375px) {
    margin-bottom: 80px;
  }

  @media (min-width: 768px) {
    width: 260px;
    margin-bottom: 100px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 120px;
    width: 300px;
  }

  @media (min-width: 1154px) {
    margin-bottom: 120;
    width: 350px;
  }

  &:last-child {
    margin-bottom: 0;
  }
  
 
`
